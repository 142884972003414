import React, {useCallback, useEffect, useMemo} from 'react'
import * as R from "ramda";
import {GroupBox} from "../field/groupbox";
import {FlexGrid} from "../../grid";
import {Tabs} from "../field/tabs";
import {
    TextInput,
    TextArea,
    Wysiwyg,
    ReactSelect,
    ExtServiceType,
    Checkbox,
    Radiobox,
    RadioboxCards,
    Image,
    Images,
    OfferUrl,
    WhitePageSettings,
    MultilangBox,
    Comments,
    Pixels,
    Files,
    CodeMirror,
    DateTime,
    DateTimeRange,
    PushNotificationSubscribeRequest,
    PushNotificationFirstTime,
    ActiveItems,
    PushNotificationPeriodPeriodically,
    PushNotificationPeriodOnce, PushNotificationPeriodByAction, PushNotificationPeriod, PrelandSettings,
} from './fields'
import {InfoBox} from "../field/infobox";
import {CloneNewAppsDetailsBlock} from "./fields/clone.newapps.details";
import {Trans, useTranslation} from "react-i18next";

// import {Select} from "./field/selects";

export function getItemDefaultValue(item, initialValues){
    //let defaultValue = ( initialValues && R.path(item.name.split('.'), initialValues) && initialValues[item.name] ) || item.defaultValue;
    // let defaultValue = initialValues[item.name] || R.path(item.name.split('.'), initialValues) || item.defaultValue;
    let defaultValue = initialValues[item.name+'_raw'] || R.path((item.name+'_raw').split('.'), initialValues);
    // console.log('defaultValue', item.name, defaultValue);
    // if(!defaultValue && defaultValue !== false) defaultValue = initialValues[item.name] || R.path(item.name.split('.'), initialValues);
    if(defaultValue === undefined) defaultValue = initialValues[item.name] || R.path(item.name.split('.'), initialValues);
    // console.log('defaultValue2', item.name, defaultValue);
    // if(!defaultValue && defaultValue !== false) defaultValue = item.defaultValue;
    if(defaultValue === undefined) defaultValue = item.defaultValue;

    if(item.type === 'checkbox')
        defaultValue = defaultValue && (defaultValue === true || defaultValue === 'true' || parseInt(defaultValue) === 1) ? 1 : 0;

    return defaultValue;
}

export default function getField(formFieldProps, errors, initialValues, item, i, itemNamePrefix = ''){
    // console.log('initialValues', formFieldProps, item.name, itemNamePrefix);

    if(item.autoTranslate === undefined || item.autoTranslate){
        if(item.label && typeof item.label === 'string') item.label = <Trans i18nKey={item.label} />;
        if(item.hint && typeof item.hint === 'string') item.hint = <Trans i18nKey={item.hint} />;
    }

    item.name = item.origname || item.name;
    // console.log('item.name', item.name, item.origname, itemNamePrefix);

    if(item.name){
        item.name = `${itemNamePrefix}${item.name}`;
        let splitedName = item.name.split('.');
        formFieldProps.key = `${item.name}-${i}`;
        formFieldProps.error = R.path(splitedName, errors) || errors[item.name];
        // formFieldProps.defaultValue = ( initialValues && R.path(item.name.split('.'), initialValues) && initialValues[item.name] ) || item.defaultValue;
        formFieldProps.defaultValue = getItemDefaultValue(item, initialValues);
        // formFieldProps.defaultValue = initialValues[item.name] || R.path(item.name.split('.'), initialValues) || item.defaultValue;
        // if(item.type === 'checkbox')
        //     formFieldProps.defaultValue = formFieldProps.defaultValue && (formFieldProps.defaultValue === true || formFieldProps.defaultValue === 'true' || parseInt(formFieldProps.defaultValue) === 1) ? 1 : 0;
    }else{
        formFieldProps.key = `${item.label || item.type}-${i}`;
        formFieldProps.defaultValue = '';
    }
    formFieldProps.fieldProps = item;
// console.log('errors', errors);
// console.log('formFieldProps', formFieldProps);

    if (item.type === 'checkbox') return <Checkbox {...formFieldProps} />
    if (item.type === 'radiobox') return <Radiobox {...formFieldProps} />
    if (item.type === 'radioboxcards') return <RadioboxCards {...formFieldProps} />

    // if (item.type === 'select') {
    //     return (
    //         <Select key={item.name+' '+i}
    //                   {...item}
    //                   errorMessage={errors[item.name] ? errors[item.name].message : ''}
    //         />
    //     )
    // }
    if (item.type === 'reactselect') return <ReactSelect {...formFieldProps} />
    if (item.type === 'textarea') return <TextArea {...formFieldProps} />
    if (item.type === 'wysiwyg') return <Wysiwyg {...formFieldProps} />
    if (item.type === 'image') return <Image {...formFieldProps} />
    if (item.type === 'images') return <Images {...formFieldProps} />
    if (item.type === 'files') return <Files {...formFieldProps} />
    if (item.type === 'codemirror') return <CodeMirror {...formFieldProps} />
    if (item.type === 'extservicetype') return <ExtServiceType {...formFieldProps} />
    if (item.type === 'offerurl') return <OfferUrl {...formFieldProps} />
    if (item.type === 'whitepage_settings') return <WhitePageSettings {...formFieldProps} />
    if (item.type === 'preland_settings') return <PrelandSettings {...formFieldProps} />
    if (item.type === 'push_period') return <PushNotificationPeriod {...formFieldProps} />
    if (item.type === 'push_period_periodically') return <PushNotificationPeriodPeriodically {...formFieldProps} />
    if (item.type === 'push_period_once') return <PushNotificationPeriodOnce {...formFieldProps} />
    if (item.type === 'push_period_byaction') return <PushNotificationPeriodByAction {...formFieldProps} />
    if (item.type === 'push_subscriberequest') return <PushNotificationSubscribeRequest {...formFieldProps} />
    if (item.type === 'push_firsttime') return <PushNotificationFirstTime {...formFieldProps} />
    if (item.type === 'multilangbox') return <MultilangBox {...formFieldProps} />
    if (item.type === 'comments') return <Comments {...formFieldProps} />
    if (item.type === 'pixels') return <Pixels {...formFieldProps} />
    if (item.type === 'datetime') return <DateTime {...formFieldProps} />
    if (item.type === 'datetimerange') return <DateTimeRange {...formFieldProps} />
    if (item.type === 'activeitems') return <ActiveItems {...formFieldProps} />
    if (item.type === 'clonenewappsdetails') return <CloneNewAppsDetailsBlock {...formFieldProps} />
    if (item.type === 'infobox') return <InfoBox key={formFieldProps.key || `infobox-${i}`} {...item} />
    if (item.type === 'groupbox') return (
        <GroupBox key={formFieldProps.key || `groupbox-${i}`} {...item}>
            {item.fields?.map((boxItem, i) => {
                return getField(formFieldProps, errors, initialValues, {...boxItem}, i, itemNamePrefix);
            })}
        </GroupBox>
    )
    if (item.type === 'flexgrid') return (
        <FlexGrid key={formFieldProps.key || `flexgrid-${i}`} {...item}
                  columns={item.columns?.map(({fields, className, ...rest}, idx, allColumns)=>{
                      return {
                          ...rest,
                          className: `py-2 px-2 ${className}`,
                          content: fields.map((_field, idx, allColumns)=>{
                              return getField(formFieldProps, errors, initialValues, {..._field}, i, itemNamePrefix);
                          }),
                      };
                  })}
        />
    )
    if (item.type === 'tabs') return (
        <Tabs key={formFieldProps.key || `tabs-${i}`} {...item}
              tabs={item.tabs?.map(({content, ...rest}, idx, allColumns)=>{
                  return {
                      ...rest,
                      content: <> {content.map((_field, idx, allColumns)=>{
                          return getField(formFieldProps, errors, initialValues, {..._field}, idx, itemNamePrefix);
                      })}</>,
                  };
              })}  />
    )


    return <TextInput {...formFieldProps} />
}
