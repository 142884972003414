

export const FINANCE = {
    TYPE: {
        TOPUP: "topup",
        SUBSCRIPTION_RENEWAL: "subscription_renewal",
        REFUND: 'refund',
        REFERRAL_BONUS: 'referral_bonus',
        WITHDRAW: 'withdraw',
        SALARY: 'salary',
        DIVIDENDS: 'dividends',
    },
    STATUS: {
        IN_PROGRESS: 1,
        COMPLETED: 2,
        ERROR: 3,
    }
}

export const SUBSCRIPTION = {
    STATUS: {
        DISABLED: 0,
        ACTIVE: 1,
    },
}

export const DOMAIN = {
    STATUS: {
        EXPIRED: 4,
        ERROR: 3,
        ACTIVE: 2,
        WAITING_TRANSFER: 1,
        NEW: 0,
    },
    POINT_TYPE: {
        NS: 'ns',
        CNAME: 'cname',
    },

}

export const AD_CAMPAIGN = {
    CATEGORY: {
        GAMBLING: 'gambling',
        DATING: 'dating',
        OTHER: 'other',
    }
}

export const APP = {
    TYPE: {
        PWA_UNIVERSAL_LINK: 'pwa_uni_link',
        CLOAK: 'cloak',
        PWA: 'pwa',
        PWA_ALL: 'pwa_all',
        ANDROID: 'android',
        IOS: 'ios',
    },
    ENGINE: {
        GP_ENGINE_1: 'gp_engine_1',
        GP_ENGINE_2: 'gp_engine_2', //flutter
        GP_ENGINE_3: 'gp_engine_3', //React Native

        IOS_ENGINE_1: 'ios_engine_1', //flutter
        IOS_ENGINE_2: 'ios_engine_2', //React Native
    },
    STATUS: {
        STATUS_WAITING: 0,
        STATUS_SUCCESS: 1,
        STATUS_ERROR: 2,
    },
    BUILD: {
        STATUS_NOT_BUILDED: 0,
        STATUS_WAITING: 1,
        STATUS_SUCCESS: 2,
        STATUS_ERROR: 3,
        SETTINGS_TYPES: [
            'hideurl', 'smartlook', 'lognavurls', 'onesignal',
            'appmetrica', 'installref', 'facebook', 'appsflyer', 'adjust'
        ]
    },
    IDLE: {
        IDLE_7DAYS: 'idle_7d',
        IDLE_30DAYS: 'idle_30d',
        IDLE_60DAYS: 'idle_60d',
        IDLE_90DAYS: 'idle_90d',
    },
    PUSH_TYPE: {
        EXTERNAL: 'external',
        INTERNAL: 'internal',
    }
}

export const VISIT_TYPE = {
    LANDING: 'landing',
    APP: 'app',
    WHITEPAGE: 'whitepage',
    BROWSER_ID: 'browserid',
}

export const POSTBACK_EVENT = {
    INSTALL: 'install',
    REG: 'reg',
    FIRST_DEPOSIT: 'first_deposit',
    DEPOSIT: 'deposit',
    SUBSCRIBE: 'subscribe',
    PUSH_SUBSCRIBE: 'push_subscribe',
    LOG: 'log',
}

export const POSTBACK_RECEIVED = {
    YES: 'yes',
    NO: 'no',
}

export const PUSH_NEWSLETTER = {
    TYPE: {
        ONCE: 'once',
        PERIODICAL: 'periodical',
        BY_ACTION: 'by_action',
    },
    TIME_PERIOD: {
        MINUTE: 'minute',
        HOUR: 'hour',
        DAY: 'day',
        MONTH: 'month',
    },
    STATUS: {
        ACTIVE: 'active',
        INACTIVE: 'inactive',
    },
}

export const WARMINGUP = {
    TYPE: {
        PIXEL_ID: 'pixelid',
        FBAPP_ID: 'fbappid',
    },
    STATUS: {
        IDLE: 0,
        IN_PROGRESS: 1,
        COMPLETED: 2,
        ERROR: 3,
    },
}

export const WHITEPAGE = {
    TYPE: {
        FILE: 'file',
        URL: 'url',
        ADCAMPAGN: 'adcampaign',
        TEMPLATE: 'template',
        ERROR_404: 'error_404'
    },
    TEMPLATE: {
        GOOGLEPLAY: 'googleplay',
        APP_V1: 'app1',
    },
}

export const PRELAND = {
    TYPE: {
        DISABLED: 'disabled',
        FILE: 'file',
        ADCAMPAGN: 'adcampaign',
    },
}

export const LOCALE = {
    FROM: {
        BROWSER: 'browser',
        IP: 'ip'
    }
}

export const OBFUSCATION_TYPE = {
    OBFUSCATION_TYPE_NO: 'no',
    OBFUSCATION_TYPE_OBFUSCAPK: 'obfuscapk',
    OBFUSCATION_TYPE_DASHO: 'dasho',
}
