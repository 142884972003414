import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'
import {TextInput as _TextInput, TextArea as _TextArea} from './../field/text-inputs'
import {Index as _Wysiwyg} from '../field/wysiwyg-editor'
import {ReactSelect as _ReactSelect} from "./../field/react-selects";
import {ExtServiceType as _ExtServiceType} from "./../field/extservice-type";
import {Checkbox as _Checkbox} from "./../field/checkbox";
import {Radiobox as _Radiobox, RadioboxCards as _RadioboxCards} from "./../field/radiobox";
import {Image as _Image, Images as _Images} from "./../field/image";
import {Comments as _Comments} from "./../app/field/comments";
import {Pixels as _Pixels} from "./../app/field/pixels";
import {Files as _Files} from "../field/files";
import {ActiveItems as _ActiveItems} from "./fields/activeitems";
import {CodeMirror as _CodeMirror} from "../field/codemirror";
import {DateTime as _DateTime, DateTimeRange as _DateTimeRange} from "../field/date-time";
import {Trans, useTranslation} from "react-i18next";
import {GroupBox} from "../field/groupbox";
import {Tabs, TABS_TYPE} from "../field/tabs";
import {useAppSelector} from "../../../redux/store";
import getField, {getItemDefaultValue} from "./get.field";
import {FiPlus} from "react-icons/fi";
import {DropdownContent} from "../../dropdown";
import Flag from "../../flag";
import {FormOfferUrlBlock} from "./fields/offer.url";
import {AD_CAMPAIGN, PRELAND, PUSH_NEWSLETTER, WHITEPAGE} from "../../../config/resources";
import {Button} from "../../button";


export const FormGenField = ({
                                 field,
                                 fieldProps,
                                 defaultValue,
                                 register,
                                 reset,
                                 error,
                                 watch,
                                 setError,
                                 setValue,
                                 control,
                                 withController = false,
                             }) => {
    if(withController){
        return (
            <Controller
                control={control}
                name={fieldProps.name}
                defaultValue={defaultValue || ''}
                rules={fieldProps.error}
                render={({ field: { ref, ...rest } }) => (
                    <field.type {...field.props}
                                {...fieldProps}
                                {...rest}
                                errorMessage={error && (error.message || " ")}
                                defaultValue={defaultValue}
                                inputRef={ref}
                                //clear props
                                watch={undefined}
                                watching={undefined}
                    />
                )}
            />
        )
    }

    const { ref, ...rest  } = register(fieldProps.name, fieldProps.error);
    return (
        <field.type key={field.name} {...field.props}
                    errorMessage={error && (error.message || " ") }
                    {...fieldProps} {...rest}
                    defaultValue={defaultValue}
                    inputRef={ref}
                    //clear props
                    watch={undefined}
                    watching={undefined}
        />
    )
}
FormGenField.propTypes = {
    field: PropTypes.element,
    fieldProps: PropTypes.object,
    defaultValue: PropTypes.any,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    register: PropTypes.func,
    reset: PropTypes.func,
    watch: PropTypes.func,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    control: PropTypes.object,
    withController: PropTypes.bool,
}


export const TextInput = (props) => (
    <FormGenField {...props} field={<_TextInput />} />
)

export const TextArea = (props) => (
    <FormGenField {...props} field={<_TextArea />} />
)

export const Wysiwyg = (props) => (
    <FormGenField {...props} withController={true} field={<_Wysiwyg />} />
)

export const ReactSelect = (props) => (
    <FormGenField {...props} withController={true} field={<_ReactSelect />} />
)

export const ExtServiceType = (props) => (
    <_ExtServiceType {...props} formRegister={props.register} />
)

export const Checkbox = (props) => (
    <FormGenField {...props} field={<_Checkbox />} />
)

export const Radiobox = (props) => (
    <FormGenField {...props} field={<_Radiobox />} />
)

export const RadioboxCards = (props) => (
    <FormGenField {...props} withController={true} field={<_RadioboxCards />} />
)

export const DateTime = (props) => (
    <FormGenField {...props} withController={true} field={<_DateTime />} />
)

export const DateTimeRange = (props) => (
    <FormGenField {...props} withController={true} field={<_DateTimeRange />} />
)

export const ActiveItems = (props) => (
    // <FormGenField {...props} field={<_ActiveItems />} />
    <_ActiveItems {...props} />
)


export const Image = (props) => {
    // const updateFieldValue = (allValues, name, value) => {
    //     return R.assocPath(
    //         name.split('.'),
    //         null,
    //         allValues
    //     );
    // }
    const onRemove = useCallback((name) => {
            props.setValue(name, null)
            // props.reset(updateFieldValue(props.getValues(), name, null));

            // props.reset({
            //     ...props.getValues(),
            //     ...{[props.fieldProps.name]: null}
            //     });
            // console.log('afterRemove', name, props.getValues())
        },
        [props]
    )
    const onDrop = useCallback(imageFile => {
            // console.log('props.name, imageFile', props.fieldProps.name, imageFile);
            props.setValue(props.fieldProps.name, imageFile);
            // console.log(props.getValues())
        },
        [props]
    )
    useEffect(
        () => {
            // console.log(props.fieldProps.name, props.defaultValue, props.getValues())
            props.setValue(props.fieldProps.name, props.defaultValue)
            // props.reset(updateFieldValue(props.getValues(), props.fieldProps.name, props.defaultValue));
            // props.reset({
            //     ...props.getValues(),
            //     ...{[props.fieldProps.name]: props.defaultValue}
            // });
            // console.log(props.getValues())
        },
        [props.defaultValue]
    );
    return (
        <FormGenField {...props} field={<_Image onDrop={onDrop} onRemove={onRemove} />} />
    )
}

export const Images = (props) => {
    const onRemove = useCallback(() => {
            // props.reset({
            //     ...props.getValues(),
            //     ...{[props.fieldProps.name]: null}
            // });
        },
        [props]
    )
    const onDrop = useCallback(imageFile => {
            // console.log('props.name, imageFile', props.fieldProps.name, imageFile);
            // props.setValue(props.fieldProps.name, imageFile);
        },
        [props]
    )
    return (
        <FormGenField {...props} withController={true} field={<_Images onDrop={onDrop} onRemove={onRemove} />} />
    )
}

export const Files = (props) => {
    return (
        <FormGenField {...props} withController={true} field={<_Files />} />
    )
}

export const CodeMirror = (props) => {
    return (
        <FormGenField {...props} withController={true} field={<_CodeMirror />} />
    )
}

export const OfferUrl = (props) => {
    const { t } = useTranslation();
    const [offers, setOffers] = useState(typeof props.defaultValue?.custom === 'object' ? Object.keys(props.defaultValue.custom) : [])
    const addOffer = useCallback(() => setOffers([
        ...offers,
        `id${Date.now()}`,
    ]), [offers]);
    const removeOffer = useCallback((idOffer, name) => {
        offers.splice(offers.indexOf(idOffer), 1);
        setOffers([...offers]);
        props.unregister(`${props.fieldProps.name}.${name}`);
    }, [offers, props.fieldProps, props.unregister]);

    let color = 'blue';
    return (
        <>
            <FormOfferUrlBlock name={'default'} isFixed={true} {...props} />
            {offers.map((offerId, idx)=>{
                return <FormOfferUrlBlock key={offerId} offerId={offerId} name={`custom.${offerId}`}
                                          onRemoveBlock={removeOffer}
                                          isFixed={false} {...props} />
            })}
            <GroupBox className={'bg-grey-100 px-4 py-2 rounded'}>
                <button onClick={addOffer}
                        type={"button"}
                        className={`w-full btn btn-default btn-rounded btn-icon bg-transparent mb-2 hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
                    <span>{t(`resource_page.adcampaign.field.offer_add_btn_for_other_geo`)}</span>
                </button>
            </GroupBox>
        </>
    )
}

export const Comments = (props) => {
    let [landingCategory] = props.watch(['landing_config.category']);
    props.fieldProps.category = landingCategory?.value || landingCategory;
    return (
        <FormGenField {...props} withController={true} field={<_Comments />} />
    )
}

export const Pixels = (props) => {
    return (
        <FormGenField {...props} withController={true} field={<_Pixels />} />
    )
}

export const WhitePageSettings = (props) => {
    const { t } = useTranslation();

    const [_wpType, setWpType] = useState(props.formInitialValues.cloak_whitepage?.type);
    if(props.formInitialValues.cloak_whitepage && props.formInitialValues.cloak_whitepage.type !== _wpType)
        props.formInitialValues.cloak_whitepage.type = _wpType;
    const wpType = props.watch('cloak_whitepage.type',
                getItemDefaultValue({
                    name: `cloak_whitepage.type`,
                    type: 'radiobox',
                },
                props.formInitialValues));

    const validateUrl = useCallback((value) => {
        if(wpType === WHITEPAGE.TYPE.URL && !value)  return t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_url')});
        return true;
    }, [wpType])

    useEffect(()=>{
        props.unregister(
            wpType === WHITEPAGE.TYPE.ADCAMPAGN ? ['cloak_whitepage.moderator_title', 'cloak_whitepage.file', 'cloak_whitepage.url']
                : (wpType === WHITEPAGE.TYPE.URL ? ['cloak_whitepage.moderator_title', 'cloak_whitepage.file'] : ['cloak_whitepage.url']),
            {keepValue: true, keepDefaultValue: true}
        );

        setWpType(wpType)
    }, [wpType])

    return (
        <GroupBox label={"White page settings"} flex={{direction: 'col', items: 'start', space: '-'}}>
            {props.fieldProps.enableFromAdCampaign && getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.adcampaign.field.cloak_whitepage_type_adcampaign`),
                    error: {required: t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_type_adcampaign')})},
                    name: `cloak_whitepage.type`,
                    type: 'radiobox',
                    value: WHITEPAGE.TYPE.ADCAMPAGN,
                },
                0)}
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.adcampaign.field.cloak_whitepage_type_url`),
                    error: {required: t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_type_url')})},
                    name: `cloak_whitepage.type`,
                    type: 'radiobox',
                    value: WHITEPAGE.TYPE.URL,
                },
                1)}
            {<div className={`ml-6 px-6 py-4 mb-4 bg-grey-100 dark:bg-grey-800 w-full ${wpType !== WHITEPAGE.TYPE.URL ? 'hidden' : ''}`}>
                {getField({...props}, props.formErrors, props.formInitialValues,
                    {
                        label: t(`resource_page.adcampaign.field.cloak_whitepage_url`),
                        name: `cloak_whitepage.url`,
                        type: 'url',
                        error: {
                            validate: validateUrl,
                            // required: t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_url')})
                        },
                        ...(wpType === WHITEPAGE.TYPE.URL ? {
                            pattern: "http[s]?://.*",
                        } : {})
                    },
                    2)}
                    </div>}
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.adcampaign.field.cloak_whitepage_type_file`),
                    error: {required: t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_type_file')})},
                    name: `cloak_whitepage.type`,
                    type: 'radiobox',
                    value: WHITEPAGE.TYPE.FILE,
                },
                3)}
            {<div className={`ml-6 px-6 py-4 mb-4 bg-grey-100 dark:bg-grey-800 w-full ${wpType !== WHITEPAGE.TYPE.FILE ? 'hidden' : ''}`}>
                {getField({...props}, props.formErrors, props.formInitialValues,
                    {
                        label: t(`resource_page.adcampaign.field.cloak_whitepage_file`),
                        error: {
                            validate: (value) => {
                                if(wpType === WHITEPAGE.TYPE.FILE && !value)  return t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_file')});
                                return true;
                            },
                            // ...(wpType === 'file' ? {required: t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_file')})} : {})
                        },
                        name: `cloak_whitepage.file`,
                        type: 'files',
                        maxFiles: 1,
                        maxSizeMb: 100,
                        accept: 'application/x-zip,application/zip,.zip',
                    },
                    5)}
            </div>}

            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.adcampaign.field.cloak_whitepage_type_${WHITEPAGE.TYPE.TEMPLATE}`),
                    error: {required: t('form.error.required', {name: t(`resource_page.adcampaign.field.cloak_whitepage_type_${WHITEPAGE.TYPE.TEMPLATE}`)})},
                    name: `cloak_whitepage.type`,
                    type: 'radiobox',
                    value: WHITEPAGE.TYPE.TEMPLATE,
                },
                7)}
            {<div className={`ml-6 px-6 py-4 mb-4 bg-grey-100 dark:bg-grey-800 w-full ${wpType !== WHITEPAGE.TYPE.TEMPLATE ? 'hidden' : ''}`}>
                {getField({...props}, props.formErrors, props.formInitialValues,
                    {
                        label: t(`resource_page.adcampaign.field.cloak_whitepage_template_select`),
                        error: {
                            validate: (value) => {
                                if(wpType === WHITEPAGE.TYPE.TEMPLATE && !value)  return t('form.error.required', {name: t(`resource_page.adcampaign.field.cloak_whitepage_template_select`)});
                                return true;
                            },
                        },
                        name: `cloak_whitepage.template_id`,
                        // hideDescription: id > 0 && true,
                        // disabled: id > 0 && true,
                        options: [WHITEPAGE.TEMPLATE.GOOGLEPLAY, WHITEPAGE.TEMPLATE.APP_V1].map((id)=>{
                            return {description: t(`resource_page.adcampaign.field.cloak_whitepage_template_${id}`), value: id,  icon: <img width={150} src={`/wp-template/${id}.png`} />, };
                        }),
                        type: 'radioboxcards',
                        inline: true,
                    },
                    8)}
                {getField({...props}, props.formErrors, props.formInitialValues,
                    {
                        label: <Trans
                                    i18nKey="resource_page.adcampaign.field.cloak_whitepage_template_app_url"
                                    components={{ a: <a /> }}
                                />,
                            // t(`resource_page.adcampaign.field.cloak_whitepage_template_app_url`),
                        name: `cloak_whitepage.template_app_url`,
                        type: 'text',
                        error: {
                            validate: (value) => {
                                if(wpType === WHITEPAGE.TYPE.TEMPLATE && !value)  return t('form.error.required', {name: t(`resource_page.adcampaign.field.cloak_whitepage_template_select`)});
                                return true;
                            },
                        },
                    },
                    2)}
            </div>}

            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.adcampaign.field.cloak_whitepage_type_${WHITEPAGE.TYPE.ERROR_404}`),
                    error: {required: t('form.error.required', {name: t(`resource_page.adcampaign.field.cloak_whitepage_type_${WHITEPAGE.TYPE.ERROR_404}`)})},
                    name: `cloak_whitepage.type`,
                    type: 'radiobox',
                    value: WHITEPAGE.TYPE.ERROR_404,
                },
                10)}

            <div className={`${wpType === WHITEPAGE.TYPE.ADCAMPAGN ? 'hidden' : ''}`}>
                {getField({...props}, props.formErrors, props.formInitialValues,
                    {
                        className: 'w-full',
                        label: t(`resource_page.adcampaign.field.cloak_whitepage_moderator_title`),
                        error: {
                            validate: (value) => {
                                if(![WHITEPAGE.TYPE.ADCAMPAGN, WHITEPAGE.TYPE.FILE, WHITEPAGE.TYPE.TEMPLATE].includes(wpType) && !value)  return t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_moderator_title')});
                                return true;
                            },
                            // required: t('form.error.required', {name: t('resource_page.adcampaign.field.cloak_whitepage_moderator_title')})
                        },
                        name: `cloak_whitepage.moderator_title`,
                        type: 'text',

                    },
                    4)}
            </div>

        </GroupBox>
    )
}

export const PrelandSettings = (props) => {
    const { t } = useTranslation();

    const [_prelandType, setPrelandType] = useState(props.formInitialValues.preland?.type);
    if(props.formInitialValues.preland && props.formInitialValues.preland.type !== _prelandType)
        props.formInitialValues.preland.type = _prelandType;
    const prelandType = props.watch('preland.type',
        getItemDefaultValue({
                name: `preland.type`,
                type: 'radiobox',
            },
            props.formInitialValues));

    useEffect(()=>{
        props.unregister(
            prelandType !== PRELAND.TYPE.FILE ? ['preland.file',] : [],
            {keepValue: true, keepDefaultValue: true}
        );

        setPrelandType(prelandType)
    }, [prelandType])

    return (
        <GroupBox label={t(`resource_page.app.field.preland_settings`)} flex={{direction: 'col', items: 'start', space: '-'}}>
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.app.field.preland_type_disabled`),
                    error: {required: t('form.error.required', {name: t('resource_page.app.field.preland_type_disabled')})},
                    name: `preland.type`,
                    type: 'radiobox',
                    value: PRELAND.TYPE.DISABLED,
                },
                0)}

            {props.fieldProps.enableFromAdCampaign && getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.app.field.preland_type_adcampaign`),
                    error: {required: t('form.error.required', {name: t('resource_page.app.field.preland_type_adcampaign')})},
                    name: `preland.type`,
                    type: 'radiobox',
                    value: PRELAND.TYPE.ADCAMPAGN,
                },
                1)}

            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`resource_page.app.field.preland_type_file`),
                    error: {required: t('form.error.required', {name: t('resource_page.app.field.preland_type_file')})},
                    name: `preland.type`,
                    type: 'radiobox',
                    value: PRELAND.TYPE.FILE,
                },
                3)}
            {<div
                className={`ml-6 px-6 py-4 mb-4 bg-grey-100 dark:bg-grey-800 w-full ${prelandType !== WHITEPAGE.TYPE.FILE ? 'hidden' : ''}`}>
                {getField({...props}, props.formErrors, props.formInitialValues,
                    {
                        label: t(`resource_page.app.field.preland_file`),
                        error: {
                            validate: (value) => {
                                if (prelandType === PRELAND.TYPE.FILE && !value) return t('form.error.required', {name: t('resource_page.app.field.preland_file')});
                                return true;
                            },
                        },
                        name: `preland.file`,
                        type: 'files',
                        maxFiles: 1,
                        maxSizeMb: 100,
                        accept: 'application/x-zip,application/zip,.zip',
                    },
                    5)}
                    {getField({...props}, props.formErrors, props.formInitialValues,
                        {
                            className: 'w-full',
                            label: t(`resource_page.app.field.preland_as_install_page`),
                            hint: t(`resource_page.app.field.preland_as_install_page_hint`),
                            name: `preland.as_install_page`,
                            type: 'checkbox',

                        },
                        6)}
            </div>}

        </GroupBox>
    )
}


export const PushNotificationPeriod = (props) => {
    const {t} = useTranslation();

    // const [_periodType, setPeriodType] = useState(props.formInitialValues.type);
    // if(props.formInitialValues.type !== _periodType) props.formInitialValues.type = _periodType;
    const periodType = props.watch('type',
        getItemDefaultValue({
                name: `type`,
                type: 'radiobox',
            },
            props.formInitialValues));

    useEffect(() => {
        if (!periodType && props.formInitialValues?.type) {
            props.setValue('type', props.formInitialValues?.type)
        }
    }, [periodType, props.formInitialValues?.type])

    // useEffect(()=>{
    //     // props.unregister(
    //     //     wpType === WHITEPAGE.TYPE.ADCAMPAGN ? ['cloak_whitepage.moderator_title', 'cloak_whitepage.file', 'cloak_whitepage.url']
    //     //         : (wpType === WHITEPAGE.TYPE.URL ? ['cloak_whitepage.moderator_title', 'cloak_whitepage.file'] : ['cloak_whitepage.url']),
    //     //     {keepValue: true, keepDefaultValue: true}
    //     // );
    //
    //     // setPeriodType(periodType)
    // }, [periodType])

    const periodCompType = useMemo(()=> {
        let compType = '';
        switch (periodType) {
            case PUSH_NEWSLETTER.TYPE.ONCE:
                compType = 'push_period_once';
                break;
            case PUSH_NEWSLETTER.TYPE.BY_ACTION:
                compType = 'push_period_byaction';
                break;
            case PUSH_NEWSLETTER.TYPE.PERIODICAL:
                compType = 'push_period_periodically';
                break;
        }
        return compType;
    }, [periodType]);

    return (
        <GroupBox label={t('form.field.push_newsletter.type')} labelBold={true} flex={{direction: 'col', items: 'start', space: '-'}}>
            {Object.values(PUSH_NEWSLETTER.TYPE).map((type, idx)=>{
                return getField({...props}, props.formErrors, props.formInitialValues,
                    {
                        label: t(`form.field.push_newsletter.type_${type}`),
                        error: {required: t('form.error.required', {name: t('form.field.push_newsletter.type')})},
                        name: `type`,
                        type: 'radiobox',
                        value: type,
                        // disabled: type === PUSH_NEWSLETTER.TYPE.BY_ACTION,
                    },
                    idx)
            })}

            {periodCompType && getField({...props}, props.formErrors, props.formInitialValues,
                {type: periodCompType, name: 'period',},
                100)}

        </GroupBox>
    )
}

export const PushNotificationPeriodPeriodically = (props) => {
    const { t } = useTranslation();

    const {name} = props.fieldProps;

    return (
        <GroupBox label={t('form.field.push_notification.period_periodically.title')}
                  labelBold={true} showBorder={false}
                  flex={{direction: 'col', items: 'start', space: '-'}}>
            <GroupBox showBorder={false}
                      flex={{direction: 'row', items: 'start', space: ''}}>
                {[0,1,2,3,4,5,6].map((day, idx)=>{
                    return getField({...props}, props.formErrors, props.formInitialValues,
                        {
                            label: t(`form.field.period.day_${day}`),
                            // className: "mt-2",
                            defaultValue: true,
                            error: {},
                            name: `${name}.day.${day}`,
                            type: 'checkbox',
                            inline: true,
                        },
                        idx)
                })}
            </GroupBox>

            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`form.field.period.time_utc`),
                    error: {
                        // required: t('form.error.required', {name: t('form.field.logo')}),
                        validate: (value) => {
                            if(/^[\d]{1,2}:[\d]{1,2}$/i.test(value)){
                                const time = value.split(':')
                                if(parseInt(time[0]) <= 24 && parseInt(time[1]) <= 59) return true;
                            }

                            return t('form.error.invalid_format', {format: 'HH:MM'});
                        },
                    },
                    className: "mt-2",
                    // defaultValue: true,
                    name: `${name}.time_utc`,
                    type: 'text',
                    placeholder: '00:00',
                    inline: false,
                },
                100)}
        </GroupBox>
    )
}

export const PushNotificationPeriodOnce = (props) => {
    const { t } = useTranslation();

    const {name} = props.fieldProps;

    return (
        <GroupBox label={t('form.field.push_notification.period_once.title')}
                  labelBold={true} showBorder={false}
                  flex={{direction: 'row', items: 'start', space: ''}}>
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`form.field.period.date`),
                    error: {required: t('form.error.required', {name: t('form.field.period.date')}),},
                    name: `${name}.date`,
                    type: 'datetime',
                    defaultValue: '',
                    className: "mt-2",
                    inline: false,
                },
                0)}
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`form.field.period.time_utc`),
                    error: {
                        // required: t('form.error.required', {name: t('form.field.logo')}),
                        validate: (value) => {
                            if(/^[\d]{1,2}:[\d]{1,2}$/i.test(value)){
                                const time = value.split(':')
                                if(parseInt(time[0]) <= 24 && parseInt(time[1]) <= 59) return true;
                            }

                            return t('form.error.invalid_format', {format: 'HH:MM'});
                        },
                    },
                    className: "mt-2",
                    // defaultValue: true,
                    name: `${name}.time_utc`,
                    type: 'text',
                    placeholder: '00:00',
                    inline: false,
                },
                100)}
        </GroupBox>
    )
}

export const PushNotificationPeriodByAction = (props) => {
    const { t } = useTranslation();

    const {name} = props.fieldProps;

    return (
        <GroupBox label={t('form.field.push_notification.period_by_action.title')}
                  labelBold={true} showBorder={false}
                  className={`w-full`}
                  flex={{direction: 'row', items: 'start', space: ''}}>
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`form.field.period.wait_for_time`),
                    error: {required: t('form.error.required', {name: t('form.field.period.wait_for_time')}),},
                    name: `${name}.wait_time`,
                    type: 'number',
                    defaultValue: '0',
                    className: "min-w-32 w-32",
                    inline: false,
                },
                0)}
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`form.field.period.title`),
                    error: {required: t('form.error.required', {name: t(`form.field.period.title`)})},
                    className: "min-w-32 w-32",
                    name: `${name}.wait_period`,
                    type: 'reactselect',
                    isClearable: false,
                    isSearchable: false,
                    isMulti: false,
                    defaultValue: [],
                    options: Object.values(PUSH_NEWSLETTER.TIME_PERIOD).map((key)=>{
                        return {value: key, label: t(`form.field.period.${key}`)}
                    }),
                    inline: false,
                },
                100)}
        </GroupBox>
    )
}

export const PushNotificationSubscribeRequest = (props) => {
    const { t } = useTranslation();
    const isEnabled = props.watch('push_service_app_data.subscribe_request_enabled',
            getItemDefaultValue({
                    name: `push_service_app_data.subscribe_request_enabled`,
                    type: 'checkbox',
                    defaultValue: true,
                },
                props.formInitialValues));

    useEffect(()=>{
        if(isEnabled) return;
        props.unregister(
            ['push_service_app_data.subscribe_request'],
            {keepValue: true, keepDefaultValue: true}
        );
    }, [isEnabled])


    return (
        <GroupBox label={t('push_notification.subscribe_request.title')} labelBold={true} showBorder={true} flex={{direction: 'col', items: 'start', space: '-'}}>

            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`push_notification.subscribe_request.enabled`),
                    className: "mt-2",
                    defaultValue: true,
                    error: {},
                    'name': 'push_service_app_data.subscribe_request_enabled',
                    type: 'checkbox',
                    inline: false,
                },
                0)}

            {isEnabled ? getField({...props}, props.formErrors, props.formInitialValues,
                {
                    'type': 'multilangbox',
                    'name': 'push_service_app_data.subscribe_request',
                    'fields': [
                        {
                            type: 'flexgrid',
                            columns: [
                                {
                                    flex: '',
                                    className: 'w-48',
                                    fields: [
                                        {
                                            label: t('form.field.icon'),
                                            // error: {required: t('form.error.required', {name: t('form.field.icon')})},
                                            name: 'icon',
                                            hint: t(`push_notification.subscribe_request.icon_hint`),
                                            type: 'image',
                                            accept: 'image/jpeg,image/png,.jpeg,.png',
                                        },
                                    ]
                                },
                                {
                                    flex: '1',
                                    // className: 'w-48',
                                    fields: [
                                        {
                                            'label': t(`push_notification.subscribe_request.message`),
                                            'error': {'required': true,},
                                            'name': 'message',
                                            'type': 'textarea',
                                            'maxLength': 90,
                                            'defaultValue': '',
                                            'placeholder': t(`push_notification.subscribe_request.message_placeholder`),
                                        },
                                        {
                                            type: 'flexgrid',
                                            columns: [
                                                {
                                                    flex: '1',
                                                    // className: 'w-48',
                                                    fields: [
                                                        {
                                                            'label': t(`push_notification.subscribe_request.button_accept`),
                                                            'error': {'required': true,},
                                                            'name': 'button_accept',
                                                            'type': 'text',
                                                            'maxLength': 15,
                                                            'placeholder': t(`push_notification.subscribe_request.button_accept_placeholder`),
                                                        },
                                                    ],
                                                },
                                                {
                                                    flex: '1',
                                                    // className: 'w-48',
                                                    fields: [
                                                        {
                                                            'label': t(`push_notification.subscribe_request.button_cancel`),
                                                            'error': {'required': true,},
                                                            'name': 'button_cancel',
                                                            'type': 'text',
                                                            'maxLength': 15,
                                                            'placeholder': t(`push_notification.subscribe_request.button_cancel_placeholder`),
                                                        },
                                                    ],
                                                },
                                            ]
                                        },
                                    ]
                                },
                            ],
                        },

                    ]
                },
                1) : null}
        </GroupBox>
    )
}

export const PushNotificationFirstTime = (props) => {
    const { t } = useTranslation();
    const isEnabled = props.watch('push_service_app_data.firsttime_notifications_enabled',
        getItemDefaultValue({
                name: `push_service_app_data.firsttime_notifications_enabled`,
                type: 'checkbox',
                defaultValue: false,
            },
            props.formInitialValues));

    useEffect(()=>{
        if(isEnabled) return;
        props.unregister(
            ['push_service_app_data.firsttime_notifications'],
            {keepValue: true, keepDefaultValue: true}
        );
    }, [isEnabled])


    let multiLangNotifications = [];
    for(let i=0;i<2;i++){
        multiLangNotifications.push(
            {
                flex: '1',
                fields: [
                    {
                        type: 'flexgrid',
                        columns: [
                            {
                                flex: '', className: 'w-40',
                                fields: [
                                    {
                                        label: t('form.field.icon'),
                                        // error: {required: t('form.error.required', {name: t('form.field.icon')})},
                                        name: `${i}.icon`,
                                        origname: `${i}.icon`,
                                        hint: t(`push_notification.firsttime_notifications.icon_hint`),
                                        dropMessage: t(`push_notification.firsttime_notifications.icon_dropmessage`),
                                        type: 'image',
                                        css: {
                                            w: 32, h: 32,
                                        },
                                        accept: 'image/jpeg,image/png,.jpeg,.png',
                                    },
                                ]
                            },
                            {
                                flex: '1',
                                fields: [
                                    {
                                        'label': t(`push_notification.firsttime_notifications.title`),
                                        'error': {'required': true,},
                                        'name': `${i}.title`,
                                        'origname': `${i}.title`,
                                        'type': 'text',
                                        'maxLength': 50,
                                        'defaultValue': '',
                                    },
                                    {
                                        'label': t(`push_notification.firsttime_notifications.message`),
                                        'error': {'required': true,},
                                        'name': `${i}.message`,
                                        'origname': `${i}.message`,
                                        'type': 'textarea',
                                        'maxLength': 110,
                                        'defaultValue': '',
                                        'placeholder': t(`push_notification.firsttime_notifications.message_placeholder`),
                                    },
                                ]
                            }
                        ],
                    }
                ]
            });
    }

    return (
        <GroupBox className={props.fieldProps.className || ''}
                  label={t('push_notification.firsttime_notifications.block_title')}
                  labelBold={true} showBorder={true}
                  flex={{direction: 'col', items: 'start', space: '-'}}>

            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    label: t(`push_notification.firsttime_notifications.enabled`),
                    className: "mt-2",
                    defaultValue: false,
                    error: {},
                    'name': 'push_service_app_data.firsttime_notifications_enabled',
                    type: 'checkbox',
                    inline: false,
                },
                0)}

            {isEnabled ? getField({...props}, props.formErrors, props.formInitialValues,
                {
                    'type': 'multilangbox',
                    'name': 'push_service_app_data.firsttime_notifications',
                    'fields': [
                        {
                            type: 'flexgrid',
                            columns: multiLangNotifications,
                        },

                    ]
                },
                1) : null}
        </GroupBox>
    )
}


function ChooseLocale({title, onSelect}){
    const { t } = useTranslation();
    const [locale, setLocale] = useState();

    const onAdd = useCallback(()=>{
        onSelect(locale.value);
    }, [locale])

    return <div className={`p-4`}>
        {title && <div className="dropdown-title">{title}</div>}
        <div className="flex-row">
            <_ReactSelect label={t(`form.field.multilangbox.chooselang.locale`)}
                    name='locale'
                    isMulti={false}
                    isSearchable={true}
                    onChange={setLocale}
                    loadOptions='localeswithcountries' />
        </div>
        <Button onClick={onAdd}>{t(`form.field.multilangbox.chooselang.btn_add`)}</Button>
    </div>
}

export const MultilangBox = ({
                                 field,
                                 fieldProps,
                                 defaultValue,
                                 register,
                                 unregister,
                                 reset,
                                 error,
                                 watch,
                                 setError,
                                 setValue,
                                 getValues,
                                 control,
                                 formInitialValues,
                                 formErrors,
                             }) => {
    const { t } = useTranslation();
    const {settings} = useAppSelector()
    const [langs, setLangs] = useState(typeof defaultValue === 'object' ? Object.keys(defaultValue) : [settings.default_lang])
    const [activeTab, setActiveTab] = useState(settings.default_lang)

    const onTabDelete = useCallback((tabIndex)=>{
        if(tabIndex === activeTab) setActiveTab(langs[0]);
        langs.splice(langs.indexOf(tabIndex), 1)
        setLangs( [...langs] );
        unregister(`${fieldProps.name}.${tabIndex}`);
    }, [langs, activeTab, unregister, fieldProps])
    const onAddLanguage = useCallback((lang)=>{
        lang = lang.toLowerCase();
        if(langs.includes(lang)) return;

        setLangs( [...langs, lang] );
        setActiveTab(lang);
    }, [langs])

    useEffect(() => {
        const _langs = formInitialValues && formInitialValues[fieldProps.name] ? Object.keys(formInitialValues[fieldProps.name]) : [];
        if(_langs.length === 0) return;

        let newLangs = [];
        _langs.forEach((lang)=>{
            if(langs.includes(lang)) return;

            newLangs.push(lang);
        })
        if(newLangs.length === 0) return;

        setLangs([
            ...langs,
            ...newLangs,
        ])

    }, [formInitialValues && formInitialValues[fieldProps.name]]);

    // const items = useMemo(()=>{
    //     return [
    //         'cz', 'de', 'dk', 'ee', 'en', 'es', 'fi', 'fr', 'hu', 'it', 'jp', 'kr', 'kz', 'lv',
    //         'no', 'pl', 'pt', 'ro', 'ru', 'tr', 'uz', 'vn', 'sk', 'ua', 'by', 'ar', 'sl', 'ph'
    //     ].filter(lang => !langs.includes(lang)).sort().map((lang)=>{
    //         let flag = lang;
    //         switch(lang){
    //             case 'en':
    //                 flag = 'us';
    //                 break;
    //             case 'ar':
    //                 flag = 'ae';
    //                 break;
    //             case 'sl':
    //                 flag = 'si';
    //                 break;
    //         }
    //         return {
    //             id: lang,
    //             title: lang.toUpperCase(),
    //             icon: <Flag code={flag} />
    //         };
    //     })
    // }, [langs]);

    const action = useMemo(()=>{
        return {
            title:  t('form.field.multilangbox.btn_add_language'),
            icon: <FiPlus />,
            // dropdownContent: <DropdownContent items={items} onSelect={onAddLanguage} />,
            dropdownContent: <ChooseLocale onSelect={onAddLanguage} />,
            // onClick: PropTypes.func,
        };
    }, [/*items,*/ onAddLanguage])

    return (
        <Tabs {...fieldProps}
              tabtype={TABS_TYPE.UNDERLINED}
              activeTab={activeTab}
              onTabChange={setActiveTab}
              onTabDelete={onTabDelete}
              action={action}
              tabs={langs.map((lang, idx, allColumns)=>{
                  return {
                      title: lang,
                      index: lang,
                      canDelete: lang !== settings.default_lang,
                      content: <>
                          {fieldProps.fields.map((_field, idx, allFields)=>{
                              return getField({
                                      register,
                                      unregister,
                                      reset,
                                      error,
                                      watch,
                                      setError,
                                      setValue,
                                      getValues,
                                      control,
                                      formInitialValues,
                                      formErrors,
                                  },
                                  formErrors,
                                  formInitialValues,
                                  {..._field, lang: lang},
                                  idx,
                                  `${fieldProps.name}.${lang}.`);
                          })}
                      </>,
                  };
              })}  />

    )
}

